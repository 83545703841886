import * as React from 'react';
import { useState } from 'react';
import { Home } from '../icons/Home';
import { Calendar } from '../icons/Calendar';
import { Dots } from '../icons/Dots';
import { navigate } from "@reach/router"
import { AnimatePresence, motion } from 'framer-motion';

const MenuItem = ({icon, text, onClick, selected}) => (
    <button className={"cursor-pointer flex flex-col justify-center items-center select-none py-6 "} onClick={onClick}>
      {icon(selected)}
      <p className={`${selected ? 'text-black' : 'text-gray-600'} text-center`}>{text}</p>
    </button>
);

const MoreItem = ({ text, onClick, selected }) => (
  <li >
    <button className={"px-6 py-5 cursor-pointer hover:bg-blue-200 w-full"}  onClick={onClick}>
      <p className={`${selected ? 'text-black' : 'text-gray-600'} hover:text-black text-left`}>{text}</p>
    </button>

  </li>
)

export const MobileMenu = ({ location }) => {
  const [isMoreOpen, setMoreOpen] = useState(false);

  return (
    <div className={"block md:hidden"}>
      <AnimatePresence>
        {isMoreOpen && (
          <motion.div
            className={"fixed bg-white w-full left-0 flex items-center pb-6 rounded"}
            initial={{ transform: 'translateY(100%)' }}
            animate={{ transform: 'translateY(0%)' }}
            exit={{ transform: 'translateY(100%)' }}
            style={{ bottom: '100px'}}
            transition={{ ease: "easeInOut", duration: 0.1 }}
          >
            <ul className={"w-full"}>
              <MoreItem text={'Comedians'}
                        onClick={() => navigate('/comedians')}
                        selected={location.pathname.includes('comedians')}
              />
              <MoreItem text={'Corporate'}
                        onClick={() => navigate('/corporate')}
                        selected={location.pathname.includes('corporate')}
              />
              <MoreItem text={'Contact Us'}
                        onClick={() => navigate('/contact-us')}
                        selected={location.pathname.includes('contact-us')}
              />
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
      <div className={"fixed border-t-2 bottom-0 bg-white w-full left-0 flex items-center justify-around "}>
        <MenuItem
          icon={(selected) => <Home selected={selected}/>}
          text={'Home'}
          onClick={() => {
            setMoreOpen(false)
            navigate('/')
          }}
          selected={!isMoreOpen && (location.pathname === '/' || location.pathname === '')}
        />
        <MenuItem
          icon={(selected) => <Calendar selected={selected}/>}
          text={'Upcoming Shows'}
          onClick={() => {
            setMoreOpen(false)
            navigate('/upcoming-shows')
          }}
          selected={!isMoreOpen && location.pathname.includes('upcoming-shows')}
        />
        <MenuItem
          icon={(selected) => <Dots selected={selected}/>}
          text={'More'}
          onClick={() => setMoreOpen(!isMoreOpen)}
          selected={isMoreOpen}
        />
      </div>
    </div>
  )
}