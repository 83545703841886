import React from 'react';
import {graphql, Link, useStaticQuery} from "gatsby";

export default () => {
    const { sanitySiteSettings } = useStaticQuery(graphql`
        query {
            sanitySiteSettings {
                _rawMenu
            }
        }
    `);

    const menu = sanitySiteSettings._rawMenu;

    return (
        <>
            <div className={"navigation-bar desktop-navigation hidden md:flex justify-between my-12"}>
                {menu.map((menuItem, index) => (
                    <Link className={"menu-item text-sm mx-4"} key={index} to={menuItem.url}>
                        {menuItem.title}
                        <div className={"menu-underline"} />
                    </Link>
                ))}
            </div>
            <hr className={"border-gray-800 my-8"}/>
        </>
    )
}
