import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

const Header = () => {
  const { sanitySiteSettings } = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        logo {
          ...ImageWithPreview
        }
      }
    }
  `);

  const { logo } = sanitySiteSettings;

  return (
    <header>
      <Image className={'max-w-full my-8 w-xl mx-auto'} width={600} {...logo} alt={'Image of ComedyBeats logo'} />
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
