/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"

import Header from "./header"
import "./layout.css"
import NavigationBar from "./NavigationBar";
import { MobileMenu } from './MobileMenu';

const Layout = ({children, location}) => {
    const { sanitySiteSettings } = useStaticQuery(graphql`
        query {
          sanitySiteSettings {
              pageTitle
            }
        }
    `);

    const pageTitle = sanitySiteSettings.pageTitle;

    return (
        <div className={"px-6 lg:px-0"}>
            <Header siteTitle={pageTitle}/>
            <div>
                <NavigationBar />
                <main>{children}</main>
                <MobileMenu location={location}/>
                <footer className={"my-8"}>
                    © {new Date().getFullYear()} ComedyBeats Ltd
                </footer>
            </div>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
